import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { PreferredSupplierBuyerListPage } from '@cotiss/preferred-supplier/pages/preferred-supplier-buyer-list.page'
import { PreferredSupplierListPage } from '@cotiss/preferred-supplier/pages/preferred-supplier-list.page'

export const PreferredSupplierLayout = memo(() => {
  return (
    <Switch>
      <Route path="/preferred-supplier/buyer/list" exact>
        <PreferredSupplierBuyerListPage />
      </Route>
      <Route path="/preferred-supplier/list/:tab?" exact>
        <PreferredSupplierListPage />
      </Route>
      <Redirect from="/preferred-supplier" to="/preferred-supplier/list/:tab?" exact />
    </Switch>
  )
})
